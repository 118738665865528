<template>
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end">
      <WButton info
        v-if="!isDisable"
        @click="openModalForCreating"
        :disabled="isDisable || isShowOverTableSpinner"
      >
        {{ $t('DITStat.createDitButton') }}
      </WButton>
    </div>
    <div class="col-lg-12">
      <div class="row">
<!--        <div class="col-lg-4 input-group input-group-sm filters__input ml-1">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--          <select-->
<!--            class="form-control form-control-sm filters__field"-->
<!--            ref="locationsSelect"-->
<!--            v-model="filters.location"-->
<!--            @change="refreshDITExports"-->
<!--          >-->
<!--            <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}-->
<!--            </option>-->
<!--            <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--              {{ location.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->

<!--        <div-->
<!--          class=" col-lg-4 d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--          <div-->
<!--            class="d-flex align-items-end justify-content-start"-->
<!--          >-->
<!--            <Switch-component-->
<!--              :disabled="this.filters.location === 'All locations'"-->
<!--              v-model="filters.with_childs"-->
<!--              class="switcher-with-childs-for-use-in-filters"-->
<!--              @change="refreshDITExports"-->
<!--            />-->
<!--          </div>-->
<!--          <div-->
<!--            class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--            <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--          </div>-->
<!--        </div>-->

                                                       <div class="col-lg-3   input-group-for-location-select-in-monitoringDITStat--filters">


                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringDITStat--filters"
                    enable-max-option-width
                    small-size
                     :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshDITExports"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


              </div>

      </div>
      <div class="row mt-h">
        <div class="col-lg-4 ">
          <div v-if="isFiltersActive" class="mb-1">
              <WButton secondary outline sm
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
        </div>
      </div>
    </div>
<!--    <div class="col-lg-12 d-flex justify-content-end">-->
<!--      <button-->
<!--        v-if="!isDisable"-->
<!--        :disabled="isDisable || isShowOverTableSpinner"-->
<!--        type="button"-->
<!--        class="btn btn-success btn-md"-->
<!--        @click="openModalForCreating"-->
<!--      >-->
<!--        {{ $t('DITStat.createDitButton') }}-->
<!--      </button>-->
<!--    </div>-->

    <div class="col-lg-12 mt-1">
      <div class="">
        <div class="animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="isShowOverTableSpinner"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('DITStat.tableTitle') }}
                  </div>
                  <div class="card-block table-block-snmp-statistic table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <!--                          <th>{{ $t('DITStat.tableColumnId') }}</th>-->
                          <th class="ip-row-in-dit-stat">{{ $t('DITStat.tableColumnFtpServer') }}</th>
                          <th>{{ $t('DITStat.tableColumnCPE') }}</th>
                          <th>{{ $t('DITStat.tableColumnFtpLogin') }}</th>
                          <th>{{ $t('DITStat.tableColumnFtpPassword') }}&nbsp;/&nbsp;{{$t('DITStat.tableColumnPrivateKey')}}<info class="ml-q" :content="$t('DITStat.tableColumnPrivateKeyInfo')" /></th>
                          <th>{{ $t('DITStat.tableColumnInterval') }}</th>
                          <!--                          <th>{{ $t('DITStat.tableColumnFormat') }}</th>-->
                          <th>{{ $t('DITStat.tableColumnEnabled') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(DITStatItem, index) in ditExportsList"
                          :data-index="index"
                          :data-id="DITStatItem.id"
                          :key="DITStatItem.id"
                          v-if="DITStatItem.id"
                          class="DITStatItem-row"
                          @click.stop="openModalForEdit(DITStatItem.id)"
                        >
                          <!--                          <td class="responsive-row max-width-column-dit-exports-table">-->
                          <!--                            <span v-tooltip="{ content: DITStatItem.id }" v-if="DITStatItem.id">{{ DITStatItem.id }}</span>-->
                          <!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                          <td>
                            <span class="mr-h" v-if="Object.prototype.hasOwnProperty.call(DITStatItem, 'sftp')">
                              <span class="ftp-type-badge-in-table badge badge-info" v-if="DITStatItem.sftp === true">
                                sftp
                              </span>
                              <span
                                class="ftp-type-badge-in-table badge badge-success"
                                v-if="DITStatItem.sftp === false"
                              >
                                ftp
                              </span>
                            </span>
                            <template v-if="DITStatItem.ftp_server">
                              <a
                                @click.stop="clickOnFtpLink"
                                v-if="
                                  linkForDITItemFTPColumnInTable(
                                    DITStatItem.ftp_server,
                                    DITStatItem.login,
                                    DITStatItem.password,
                                    DITStatItem.sftp,
                                    DITStatItem.private_key
                                  ) !== undefined
                                "
                                :href="
                                  linkForDITItemFTPColumnInTable(
                                    DITStatItem.ftp_server,
                                    DITStatItem.login,
                                    DITStatItem.password,
                                    DITStatItem.sftp,
                                    DITStatItem.private_key
                                  )
                                "
                              >
                                {{ DITStatItem.ftp_server }}
                              </a>
                              <span v-else>{{ DITStatItem.ftp_server }}</span>
                            </template>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td>
                            <span
                              v-tooltip="{content: tooltipForDITItemCpeColumnInTable(DITStatItem.ids),
                              popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }}"
                              class="text-info tooltip-text ml-q"
                              v-if="DITStatItem.ids"
                            >
                              {{ cpesCount(DITStatItem.ids) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row max-width-column-dit-exports-table">
                            <span v-tooltip="{ content: DITStatItem.login,
                            popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          } }" v-if="DITStatItem.login">
                              {{ DITStatItem.login }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
<!--                          <td class="responsive-row max-width-column-dit-exports-table">-->
<!--                            <span v-tooltip="{ content: DITStatItem.password }" v-if="DITStatItem.password">-->
<!--                              {{ DITStatItem.password }}-->
<!--                            </span>-->
<!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
<!--                          </td>-->
                          <td class="responsive-row max-width-column-dit-exports-table">
                            <span v-tooltip="{ content: passwordOrKeyForShowInTable(DITStatItem),
                            popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }}"
                                  v-if="(DITStatItem.password) ||
                                  (DITStatItem.sftp && DITStatItem.private_key !== '')">
                              {{ passwordOrKeyForShowInTable(DITStatItem) }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="DITStatItem.interval">
                              {{ DITStatItem.interval }} {{ $t('general.minShort') }}.
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <!--                          <td class="responsive-row">-->
                          <!--                            <span v-if="DITStatItem.format">{{ DITStatItem.format.toUpperCase() }}</span>-->
                          <!--                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                          <td class="responsive-row">
                            <template v-if="DITStatItem.hasOwnProperty('enabled')">
                              <span v-if="DITStatItem.enabled === true">
                                <span class="badge-cpe badge-status badge-status__connected"></span>
                                {{ $t('general.enabled') }}
                              </span>
                              <span v-else-if="DITStatItem.enabled === false">
                                <span class="badge-cpe badge-status badge-status__disconnected"></span>
                                {{ $t('general.disabled') }}
                              </span>
                            </template>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                        </tr>

                        <tr v-if="!ditExportsList.length">
                          <td colspan="20" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal title="Edit OR New DIT" v-model="isEditOrNewModalShowing" class="modal-info" effect="fade/zoom">
      <loader-spinner v-if="isShowOverCreateOrEditMOdalSpinner" centered></loader-spinner>
      <h4 slot="modal-title" class="modal-title">
        {{ isCreatingNewDITExportModal ? $t('DITStat.modalHeaderForNew') : $t('DITStat.modalHeaderForEdit') }}
      </h4>
      <div>
        <!--        <pre>{{ updatedOrNewDITExport }}</pre>-->
        <div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group mt-1">
                <div>
                  <label for="dit-export-enabled">{{ $t('DITStat.tableColumnEnabled') }}</label>
                </div>
                <Switch-component
                  v-model="updatedOrNewDITExport.enabled"
                  :disabled="isDisable"
                  :label="$t('DITStat.modalEnebledCaption')"
                  id="dit-export-enabled"
                />
              </div>

              <div class="filter mb-1 cpe-select-block">
                <Loader-spinner-small centered v-if="isCpesListFiltering"></Loader-spinner-small>
                <div>
                  <div
                    :class="{
                      'showing-filters-for-cpe-list-block-button': !filtersForCpeList.isFiltersForCpeListBlockShowing
                    }"
                    @click="toggleIsFiltersForCpeListBlockShowing"
                    role="button"
                    v-if="!isDisable"
                  >
                    <transition name="fadeforcpefiltersblockinDITExports">
                      <i v-if="filtersForCpeList.isFiltersForCpeListBlockShowing" class="icon-arrow-down"></i>
                      <i v-else class="icon-arrow-right"></i>
                    </transition>
                    {{ $t('DITStat.filtersForCpeListSwitcherCaption') }}
                    &nbsp;
                    <transition name="fadeforcpefiltersblockinDITExports">
                      <span
                        class="badge badge-light badge-success"
                        v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                      >
                        {{ $t('DITStat.filtersForCpeListFiltringForCpeListIsEnabled') }} &nbsp;
                      </span>
                    </transition>
                    &nbsp;
                    <info :content="$t('DITStat.filtersForCpeListSwitcherInfoTooltip')" />
                  </div>
                </div>
                <transition name="fadeforcpefiltersblockinDITExports">
                  <div
                    v-if="filtersForCpeList.isFiltersForCpeListBlockShowing === true && !isDisable"
                    class="filters-for-cpe-list ml-2 mt-1"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-q">
                          <label for="locationForCpeFilter">
                            {{ $t('DITStat.filtersForCpeListSwitcherLocationCaption') }}
                          </label>
                          <select
                            v-model="filtersForCpeList.location"
                            name="locationForCpeFilter"
                            id="locationForCpeFilter"
                            :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"
                            class="form-control"
                            :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"
                          >
                            <option :value="'All locations'">{{ $t('general.allLocations') }}</option>
                            <option v-for="location in locationsList" :key="location.id" :value="location.id">
                              {{ location.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-6"></div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group mt-q d-flex justify-content-between">
                          <WButton secondary sm fullWidth
                            @click="addAllFilteredCpesToSelected"
                            :disabled="filterdCpesListForModal.length === 0"
                          >
                            <span>
                              {{ $t('DITStat.selectAllCpesButtonCaption') }}
                              <span>({{ this.filterdCpesListForModal.length }})</span>
                            </span>
                          </WButton>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group mt-q d-flex justify-content-between">
                          <WButton secondary sm fullWidth
                            customClass="mt-q p-q"
                            @click.stop="clearSelectedCpesList"
                          >
                            <span class="fa fa-close"></span>
                            <span>{{ $t('DITStat.cancelSelectAllCpesButtonCaption') }}</span>
                          </WButton>
                        </div>
                      </div>
                    </div>
                    <div class="mt-0 mb-1">
                      <transition name="fadeforcpefiltersblockinDITExports">
                        <small
                          v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                          :class="{ 'item-for-delete': !isDisable }"
                          @click="clearFiltersForCpeList"
                          class=""
                        >
                          <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                          {{ $t('DITStat.filtersForCpeListResetFiltersButton') }}
                        </small>
                      </transition>
                    </div>
                  </div>
                </transition>
              </div>

              <!--              <div class="form-group mt-1">-->
              <!--                <div>-->
              <!--                  <label for="dit-export-select-cpes">{{ $t('DITStat.modalCPEsCaption') }}</label>-->
              <!--                </div>-->
              <!--                <div class="w-100 text-right mb-q selected-cpes-counter-in-DIT-exports-modal"><small class="text-muted">{{$t('DITStat.modalSelectedCPEsCaption')}}: {{selectedCpesCount}}</small></div>-->
              <!--                <multiselect-->
              <!--                  @input="handleModalCpesMultiselectFieldInput"-->
              <!--                  id="dit-export-select-cpes"-->
              <!--                  :closeOnSelect="true"-->
              <!--                  :hideSelected="true"-->
              <!--                  :disabled="isDisable"-->
              <!--                  :multiple="true"-->
              <!--                  v-model="cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector"-->
              <!--                  :options="filterdCpesListForModal || []"-->
              <!--                  label="name"-->
              <!--                  trackBy="id"-->
              <!--                  :selectedLabel="$t('general.multiSelectSelected')"-->
              <!--                  selectLabel="+"-->
              <!--                  deselectLabel="-"-->
              <!--                  :placeholder="$t('DITStat.modalCpeListPlaceholder')"-->
              <!--                  class="input multiselect-max-height-in-DIT-export-modal"-->
              <!--                  :class="{ 'is-danger': isCpesLengthErrorShowing === true }"-->
              <!--                >-->
              <!--                  <template slot="option" slot-scope="props">-->
              <!--                    <div class="option__desc">-->
              <!--                    <span-->
              <!--                      class="badge-cpe badge-status ml-h tooltip-text"-->
              <!--                      :class="{-->
              <!--                        'badge-status__connected': props.option.connected,-->
              <!--                        'badge-status__disconnected': !props.option.connected-->
              <!--                      }"-->
              <!--                      v-tooltip.top-center="{-->
              <!--                        content: `<ul class='list-unstyled text-left m-0'>-->
              <!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
              <!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
              <!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
              <!--                                        </ul>`-->
              <!--                      }"-->
              <!--                    >-->
              <!--                      <span v-if="props.option.name">{{ props.option.name }}</span>-->
              <!--                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
              <!--                    </span>-->
              <!--                    </div>-->
              <!--                  </template>-->
              <!--                  <span slot="noResult">-->
              <!--                    <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">{{$t('DITStat.modalCpeListNoResultMsgWhenFilterEnabled')}}</small>-->
              <!--                    <small v-else>{{$t('DITStat.modalCpeListNoResultMsg')}}</small>-->
              <!--                </span>-->
              <!--                  <span slot="noOptions">-->
              <!--                    <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">{{$t('DITStat.modalCpeListNoOptionsMsgWhenFilterEnabled')}}</small>-->
              <!--                    <small v-else>{{$t('DITStat.modalCpeListNoOptionsMsg')}}</small>-->
              <!--                </span>-->
              <!--                </multiselect>-->
              <!--                                <span v-show="isCpesLengthErrorShowing === true"-->
              <!--                      class="help is-danger">{{$t('DITStat.modalSelectedCpesLengthError')}}</span>-->
              <!--              </div>-->
              <div class="form-group mt-1">
                <div>
                  <label for="dit-export-select-cpes">{{ $t('DITStat.modalCPEsCaption') }}</label>
                </div>
                <div class="w-100 text-right mb-q selected-cpes-counter-in-DIT-exports-modal">
                  <small class="text-muted">
                    {{ $t('DITStat.modalSelectedCPEsCaption') }}: {{ selectedCpesCount }}
                  </small>
                </div>
                <multiselect
                  @input="handleModalCpesMultiselectFieldInput"
                  id="dit-export-select-cpes"
                  :closeOnSelect="true"
                  :hideSelected="true"
                  :disabled="isDisable"
                  :multiple="true"
                  v-model="cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector"
                  :options="filterdCpesListForModal || []"
                  label="name"
                  trackBy="id"
                  :selectedLabel="$t('general.multiSelectSelected')"
                  selectLabel="+"
                  deselectLabel="-"
                  :placeholder="$t('DITStat.modalCpeListPlaceholder')"
                  class="input multiselect-max-height-in-DIT-export-modal"
                  :class="{ 'is-danger': isCpesLengthErrorShowing === true }"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                      <span
                        class="badge-cpe badge-status ml-h tooltip-text"
                        :class="{
                          'badge-status__connected': props.option.connected,
                          'badge-status__disconnected': !props.option.connected
                        }"
                        v-tooltip.top-center="{
                          content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`,
                                        popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }"
                      >
                        <span v-if="props.option.name">{{ props.option.name }}</span>
                        <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                      </span>
                    </div>
                    <div class="option__desc" v-if="showDescriptionInCpesFilter">
                      <span
                        class="badge-cpe badge-status ml-h tooltip-text"
                        :class="{
                          'badge-status__connected': props.option.connected,
                          'badge-status__disconnected': !props.option.connected
                        }"
                        v-tooltip.top-center="{
                          content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                            props.option.description ? props.option.description : $t('general.no').toLowerCase()
                          }</small></li>
                                        </ul>`,
                          popperOptions: {
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }"
                      >
                        <template v-if="props.option.name">
                          <span v-if="!props.option.description">
                            {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                          </span>
                          <span v-if="props.option.description">
                            {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                            {{ cutOffLongTextForCPEDescription(props.option.description) }}
                          </span>
                        </template>
                        <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                      </span>
                    </div>
                  </template>
                  <span slot="noResult">
                    <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                      {{ $t('DITStat.modalCpeListNoResultMsgWhenFilterEnabled') }}
                    </small>
                    <small v-else>{{ $t('DITStat.modalCpeListNoResultMsg') }}</small>
                  </span>
                  <span slot="noOptions">
                    <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                      {{ $t('DITStat.modalCpeListNoOptionsMsgWhenFilterEnabled') }}
                    </small>
                    <small v-else>{{ $t('DITStat.modalCpeListNoOptionsMsg') }}</small>
                  </span>
                </multiselect>
                <div class="w-100 d-flex justify-content-between">
                  <div>
                    <span v-show="isCpesLengthErrorShowing === true" class="help is-danger">
                      {{ $t('DITStat.modalSelectedCpesLengthError') }}
                    </span>
                  </div>
                  <div style="text-align: right;">
                    <a
                      href="#"
                      class="ml-q cpes-show-description"
                      @click.prevent="toggleShowingDescriptionInCpesFilter"
                    >
                      {{
                        showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription')
                      }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="dit-export-server-ftp">{{ $t('DITStat.modalFTPServerCaption') }}</label>
                <input
                  type="text"
                  :data-vv-as="$t('DITStat.modalFTPServerCaption')"
                  id="dit-export-server-ftp"
                  :placeholder="$t('DITStat.modalFTPServerPlaceholder')"
                  name="dit-export-server-ftp"
                  :disabled="isDisable"
                  v-validate="'required|max:50|ditExportsFtpServerIPAndPort'"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('dit-export-server-ftp') }"
                  v-model="updatedOrNewDITExport.ftp_server"
                  @input="handleModalFTPServerFieldInput"
                  data-vv-validate-on="none"
                />
                <span v-show="errors.has('dit-export-server-ftp')" class="help is-danger">
                  {{ errors.first('dit-export-server-ftp') }}
                </span>
              </div>
              <div class="form-group">
                <Switch-component
                  @change="sftpSwitcherInModalChanged"
                  :disabled="isDisable"
                  id="sftp-switcher"
                  name="sftp-switcher"
                  v-model="updatedOrNewDITExport.sftp"
                  :label="$t('DITStat.sftpSwitcherCaption')"
                />
                <info class="ml-q" :content="$t('DITStat.sftpSwitcherInfo')" />
              </div>
              <div class="form-group">
                <label for="dit-export-server-login">{{ $t('DITStat.modalLoginCaption') }}</label>
                <input
                  type="text"
                  :data-vv-as="$t('DITStat.modalLoginCaption')"
                  id="dit-export-server-login"
                  :placeholder="$t('DITStat.modalLoginPlaceholder')"
                  name="dit-export-server-login"
                  :disabled="isDisable"
                  v-validate="'required|max:50'"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('dit-export-server-login') }"
                  v-model="updatedOrNewDITExport.login"
                  @input="handleModalFTPServerLoginFieldInput"
                  data-vv-validate-on="none"
                />
                <span v-show="errors.has('dit-export-server-login')" class="help is-danger">
                  {{ errors.first('dit-export-server-login') }}
                </span>
              </div>
<!--              <div class="form-group" v-if="updatedOrNewDITExport.sftp">-->
<!--                <label for="dit-export-server-sftp-auth-type">{{ $t('DITStat.modalSFTPAuthTypeCaption')-->
<!--                  }}</label>-->
<!--                <select v-model="selectedSFTPAuthType" name="dit-export-server-sftp-auth-type"-->
<!--                        id="dit-export-server-sftp-auth-type" class="form-control">-->
<!--                  <option v-for="type of this.SFT_AUTH_TYPE" :key="type" :value="type">{{-->
<!--                    type }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
              <transition name="ditExportsModalTransition" mode="out-in">
              <div class="form-group" v-if="updatedOrNewDITExport.sftp" :key="'buttons-for-pass-key-select'">
                <label>{{ $t('DITStat.modalSFTPAuthTypeCaption')
                  }}</label>
                <div>
                  <div class="btn-group btn-group-sm " data-toggle="button">
                    <label class="btn btn btn-outline-success"
                           :class="{ active: selectedSFTPAuthType === 'password' }">
                      <input type="radio" name="authtypeFilter"
                             @click="clickSetSelectedSFTPAuthTypeButtonHandler('password')"/>
                      {{ $t('DITStat.SFTPAuthTypePassword') }}
                    </label>
                    <label class="btn btn btn-outline-success"
                           :class="{ active: selectedSFTPAuthType === 'private_key' }">
                      <input type="radio" name="authtypeFilter"
                             @click="clickSetSelectedSFTPAuthTypeButtonHandler('private_key')"/>
                      {{ $t('DITStat.SFTPAuthTypePrivateKey') }}
                    </label>
                  </div>
                </div>
              </div>
              </transition>
              <transition name="ditExportsModalTransition" mode="out-in">
              <div class="form-group" v-if= "!updatedOrNewDITExport.sftp || (updatedOrNewDITExport.sftp && selectedSFTPAuthType === 'password')" :key="'password-enter'">
                <label for="dit-export-server-password">{{ $t('DITStat.modalPasswordCaption') }}</label>
                <input
                  type="text"
                  :data-vv-as="$t('DITStat.modalPasswordCaption')"
                  id="dit-export-server-password"
                  :placeholder="$t('DITStat.modalPasswordPlaceholder')"
                  name="dit-export-server-password"
                  :disabled="isDisable"
                  v-validate="'required|max:50'"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('dit-export-server-password') }"
                  v-model="updatedOrNewDITExport.password"
                  @input="handleModalFTPServerPasswordFieldInput"
                  data-vv-validate-on="none"
                />
                <span v-show="errors.has('dit-export-server-password')" class="help is-danger">
                  {{ errors.first('dit-export-server-password') }}
                </span>
              </div>
              <div class="form-group" v-if= "selectedSFTPAuthType === 'private_key' && updatedOrNewDITExport.sftp" :key="'private_key-enter-or-delete'">
                <transition name="ditExportsModalTransition" mode="out-in">
                <div class="form-group" v-if= "updatedOrNewDITExport.private_key !== 'OK' || changingPrivateKeyMode" :key="'private_key-enter'">
                  <label for="dit-export-server-private-key">{{ $t('DITStat.modalPrivateKeyCaption') }}</label>
                  <textarea
                    type="text"
                    :data-vv-as="$t('DITStat.modalPrivateKeyCaption')"
                    id="dit-export-server-private-key"
                    :placeholder="$t('DITStat.modalPrivateKeyPlaceholder')"
                    name="dit-export-server-private-key"
                    :disabled="isDisable"
                    v-validate="'required'"
                    class="form-control dit-text-area-for-private-key"
                    :class="{ input: true, 'is-danger': errors.has('dit-export-server-private-key') }"
                    v-model="updatedOrNewDITExport.private_key"
                    @input="handleModalFTPServerPrivateKeyFieldInput"
                    data-vv-validate-on="none"
                  />
                  <span v-show="errors.has('dit-export-server-private-key')" class="help is-danger">
                    {{ errors.first('dit-export-server-private-key') }}
                  </span>
                </div>
                <div class="form-group" v-if= "updatedOrNewDITExport.private_key === 'OK' && !changingPrivateKeyMode" :key="'private_key-delete'">
                  <label for="dit-export-server-private-key">{{ $t('DITStat.modalPrivateKeyCaption') }}</label>
                  <div>
<!--                    {{updatedOrNewDITExport.private_key}}-->
                    <WButton warning outline
                      @click="changePrivateKeyButtonHandler"
                    >
                      {{ $t('DITStat.modalPrivateKeyChangeButton') }}
                    </WButton>
                  </div>
                </div>
                </transition>
              </div>
              </transition>
              <div class="form-group">
                <label for="dit-export-server-interval">
                  {{ $t('DITStat.modalIntervalCaption') }} ({{ $t('DITStat.modalIntervalMinutes') }})
                </label>
                <input
                  type="number"
                  :data-vv-as="$t('DITStat.modalIntervalCaption')"
                  id="dit-export-server-interval"
                  :placeholder="$t('DITStat.modalIntervalPlaceholder')"
                  name="dit-export-server-interval"
                  :disabled="isDisable"
                  v-validate="'required|numeric'"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('dit-export-server-interval') }"
                  v-model.number="updatedOrNewDITExport.interval"
                  @input="handleModalExportIntervalFieldInput"
                  data-vv-validate-on="none"
                />
                <span v-show="errors.has('dit-export-server-interval')" class="help is-danger">
                  {{ errors.first('dit-export-server-interval') }}
                </span>
              </div>
              <!--              <div class="form-group">-->
              <!--                <label for="dit-export-server-format">{{ $t('DITStat.modalFormatCaption')-->
              <!--                  }}</label>-->
              <!--                <select v-model="updatedOrNewDITExport.format" name="dit-export-server-format"-->
              <!--                        id="dit-export-server-format" class="form-control">-->
              <!--                  <option v-for="format of this.DIT_EXPORT_FORMAT" :key="format" :value="format">{{-->
              <!--                    format }}-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--                &lt;!&ndash;                <span v-show="errors.has('dit-export-server-format')"&ndash;&gt;-->
              <!--                &lt;!&ndash;                      class="help is-danger">{{ errors.first('dit-export-server-format') }}</span>&ndash;&gt;-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer d-flex justify-content-between">
        <div>
          <WButton success outline
            v-if="isCreatingNewDITExportModal === true && !isDisable"
            @click="createDITExport"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
          >
            {{ $t('general.create') }}
          </WButton>
          <WButton success outline
            v-if="isCreatingNewDITExportModal === false && !isDisable"
            @click="updateDITExport"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
          >
            {{ $t('general.save') }}
          </WButton>
        </div>
        <div>
          <WButton secondary
            v-if="isCreatingNewDITExportModal === true"
            @click="closeModalForCreating"
            :disabled="isShowOverCreateOrEditMOdalSpinner"
          >
            {{ $t('general.close') }}
          </WButton>
          <WButton danger outline
            customClass="mr-1"
            v-if="isCreatingNewDITExportModal === false && !isDisable"
            @click="openDeleteDitModal"
            :disabled="isShowOverCreateOrEditMOdalSpinner || isDisable"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary
            v-if="isCreatingNewDITExportModal === false"
            @click="closeModalForEdit"
            :disabled="isShowOverCreateOrEditMOdalSpinner"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>
    <modal title="Delete DIT" v-model="isdeletingDitExportModalShowing" class="modal-danger" effect="fade/zoom">
      <loader-spinner v-if="isShowOverDeleteModalSpinner" centered></loader-spinner>
      <h4 slot="modal-title" class="modal-title">{{ $t('DITStat.deleteDitModalHeader') }}</h4>
      {{ $t('DITStat.deleteDitModalText') }}
      <b>{{ updatedOrNewDITExport.ftp_server }}</b>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="closeDeleteDitModal"
          :disabled="isShowOverDeleteModalSpinner"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteDITExport"
          :disabled="isShowOverDeleteModalSpinner"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import DITStatService from '../../services/DITStatService';
import cpeService from '../../services/cpeService';
import Modal from '../../components/Modal.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import helpers from '../../helpers';
import locationService from '../../services/locationService';
import Info from '../../components/Universal/info-icon.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';

import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

const DIT_EXPORT_FORMAT = ['json'];
const SFT_AUTH_TYPE = ['password', 'private_key'];

export default {
  name: 'DITStat',
  components: {
    Modal, SwitchComponent, Multiselect, Info, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      changingPrivateKeyMode: false, // используем при редактировании sftp экспорта с ключем для показа ключа
      selectedSFTPAuthType: 'password',
      charactersForCpeListSeparateNameFromDescription: ':::', // используем при создании списка CPE с описаниями  для мультиселекта
      showDescriptionInCpesFilter: false,
      isCpesLengthErrorShowing: false,
      isdeletingDitExportModalShowing: false,
      cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector: [],
      ditExportsList: [],
      updatedOrNewDITExport: {},
      isEditOrNewModalShowing: false,
      isCreatingNewDITExportModal: true, // чтобы посмотреть - модалка для создагия или для редактирования
      newDitExport: {
        interval: '',
        format: 'json',
        ftp_server: '',
        login: '',
        password: '',
        enabled: false,
        ids: [],
        sftp: false,
        private_key: ''
      },
      filtersForCpeList: {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations'
      },
      filters: {
        location: 'All locations',
        with_childs: false,
      }
    };
  },
  methods: {
    refreshDITExports() {
      this.getAllDitExpots();
    },
    changePrivateKeyButtonHandler(){
      this.changingPrivateKeyMode = true;
      this.updatedOrNewDITExport.private_key = '';
    },
    setSelectedSFTPAuthTypeWhenOpenModal(ditExport) {
      // в зависимости от того какой дить экспорт октрываем (с ключем или паролем)
      // ставим в нужное состоянее соотвествующюю переменную
      try {
        if (Object.prototype.hasOwnProperty.call(ditExport, 'private_key') &&
          ditExport.private_key !== '' &&
          Object.prototype.hasOwnProperty.call(ditExport, 'sftp') &&
          ditExport.sftp === true
        ) {
          this.setSelectedSFTPAuthType('private_key');
        } else {
          this.setSelectedSFTPAuthType('password');
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'

      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshDITExports()


    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     location: 'All locations',
    //     with_childs: false,
    //   }
    //   this.refreshDITExports()
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        // location: 'All locations',
        // with_childs: false,
      }
       if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
         this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.refreshDITExports()
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    setSelectedSFTPAuthType(value) {
      this.selectedSFTPAuthType = value;
    },
    clickSetSelectedSFTPAuthTypeButtonHandler(value) {
      // this.updatedOrNewDITExport.password = '';
      // this.updatedOrNewDITExport.private_key = '';
      if (value === 'password') {
        // если переключаемя на обыфчный пароль,  чистим приватный ключ
        this.updatedOrNewDITExport.private_key = '';
      }
      this.setSelectedSFTPAuthType(value);
    },
    sftpSwitcherInModalChanged(e) {
      // console.log(e.target.value);
      // console.log(this.updatedOrNewDITExport.sftp);
      if (e.target.value === false) {
        // если переключаемя на обыфчный ftp чистим приватный ключ
        this.updatedOrNewDITExport.private_key = '';
      }
    },
    passwordOrKeyForShowInTable(ditExport) {
      try {
        // возвращаем пароль или надпись ключ для отображения в табличке в зависимости от типав сервера
        if (Object.prototype.hasOwnProperty.call(ditExport, 'private_key') &&
          ditExport.private_key !== '' &&
          Object.prototype.hasOwnProperty.call(ditExport, 'sftp') &&
          ditExport.sftp === true
        ) {
          // если sftp и применяется ключ - возвращаем надпись что ключ
          return `${this.$t('DITStat.tableColumnPrivateKey').toLowerCase()}`;
        }
        if (ditExport.password) {
          // если есть пароль - возвращаем пароль
          return `${this.$t('DITStat.tableColumnFtpPassword').toLowerCase()}: ${ditExport.password}`;
        }
        // если ничего нет - возвращаем нет
        return `${this.$t('general.no').toLowerCase()}`;
      } catch (e) {
        console.log(e);
        return `${this.$t('DITStat.tableColumnFtpPassword').toLowerCase()}: ${ditExport.password}`;
      }
    },
    clickOnFtpLink() {},
    cutOffLongTextForCPEDescription(text) {
      try {
        if (text.length > 60) {
          return `${text.slice(0, 60)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    toggleShowingDescriptionInCpesFilter() {
      this.showDescriptionInCpesFilter = !this.showDescriptionInCpesFilter;
    },
    showCpesLengthError() {
      this.isCpesLengthErrorShowing = true;
    },
    hideCpesLengthError() {
      this.isCpesLengthErrorShowing = false;
    },
    // handleModalCpesMultiselectFieldInput(){
    //   this.hideCpesLengthError()
    // },
    handleModalCpesMultiselectFieldInput() {
      this.hideCpesLengthError();
      try {
        if (Array.isArray(this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector)) {
          for (const cpe of this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector) {
            if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
              cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0];
            }
          }
        }
      } catch (e) {}
    },
    handleModalFTPServerFieldInput() {
      // console.log('input')
      // очищаем ошибку поля dit-export-server-ftp, если она есть, каждый раз когда начанаем вводить в dit-export-server-ftp
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dit-export-server-ftp')) {
          this.errors.remove('dit-export-server-ftp');
        }
      }
    },
    handleModalFTPServerLoginFieldInput() {
      // console.log('input')
      // очищаем ошибку поля dit-export-server-login, если она есть, каждый раз когда начанаем вводить в dit-export-server-login
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dit-export-server-login')) {
          this.errors.remove('dit-export-server-login');
        }
      }
    },
    handleModalFTPServerPasswordFieldInput() {
      // console.log('input')
      // очищаем ошибку поля dit-export-server-password, если она есть, каждый раз когда начанаем вводить в dit-export-server-password
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dit-export-server-password')) {
          this.errors.remove('dit-export-server-password');
        }
      }
    },
    handleModalFTPServerPrivateKeyFieldInput() {
      // console.log('input')
      // очищаем ошибку поля dit-export-server-private-key если она есть, каждый раз когда начанаем вводить в dit-export-server-private-key
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dit-export-server-private-key')) {
          this.errors.remove('dit-export-server-private-key');
        }
      }
    },
    handleModalExportIntervalFieldInput() {
      // console.log('input')
      // очищаем ошибку поля dit-export-server-interval, если она есть, каждый раз когда начанаем вводить в dit-export-server-interval
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('dit-export-server-interval')) {
          this.errors.remove('dit-export-server-interval');
        }
      }
    },
    clearValidatorErrors() {
      this.errors.clear();
      this.hideCpesLengthError();
    },
    openDeleteDitModal() {
      this.isdeletingDitExportModalShowing = true;
    },
    closeDeleteDitModal() {
      this.isdeletingDitExportModalShowing = false;
    },
    openModalForCreating() {
      this.selectedSFTPAuthType = 'password';
      this.showDescriptionInCpesFilter = false;
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector = [];
      this.isCreatingNewDITExportModal = true;
      this.updatedOrNewDITExport = JSON.parse(JSON.stringify(this.newDitExport));
      this.showEditOrNewDITModal();
    },
    closeModalForCreating() {
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.hideEditOrNewDITModal();
      this.updatedOrNewDITExport = {};
      this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector = [];
      this.changingPrivateKeyMode = false;
    },
    openModalForEdit(ditExportId) {
      this.showDescriptionInCpesFilter = false;
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector = [];
      this.isCreatingNewDITExportModal = false;
      const ditExport = this.returnCopyOfDITExportByID(ditExportId);
      if (ditExport !== undefined) {
        this.updatedOrNewDITExport = ditExport;
        this.setSelectedSFTPAuthTypeWhenOpenModal(ditExport);
        // наполняем мультиселектор точек доступа точками из объекта ДИТ экспорта
        const { cpesList } = this;
        if (cpesList && this.updatedOrNewDITExport && this.updatedOrNewDITExport.ids) {
          const cpesItems = helpers.transformArrayToObject(cpesList);
          this.updatedOrNewDITExport.ids.map((cpe) => {
            if (Object.prototype.hasOwnProperty.call(cpesItems, cpe)) {
              const fullCpe = cpesItems[cpe];
              fullCpe.id = cpe;
              // console.log(fullCpe);
              this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.push(fullCpe);
            } else {
              const fullCpe = { name: cpe, id: cpe };
              fullCpe.id = cpe;
              // console.log(fullCpe);
              this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.push(fullCpe);
            }
          });
        }
        this.showEditOrNewDITModal();
      }
    },
    closeModalForEdit() {
      this.clearValidatorErrors();
      this.clearFiltersForCpeList();
      this.hideEditOrNewDITModal();
      this.updatedOrNewDITExport = {};
      this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector = [];
      this.changingPrivateKeyMode = false;
    },
    showEditOrNewDITModal() {
      this.isEditOrNewModalShowing = true;
    },
    hideEditOrNewDITModal() {
      this.isEditOrNewModalShowing = false;
    },
    returnCopyOfDITExportByID(ditExportId) {
      try {
        for (const item of this.ditExportsList) {
          if (item.id === ditExportId) {
            return JSON.parse(JSON.stringify(item));
          }
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    },
    getAllDitExpots() {
      DITStatService.getDITStatList(this);
    },
    getCompactCpesList() {
      // cpeService.getAllCompactCpes(this);
      cpeService.getAllCompactCpesWithDataRemaining(this);
    },
    getLocations() {
      locationService.getLocations(this);
    },
    cpesCount(cpeIds) {
      try {
        return cpeIds.length;
      } catch (e) {
        return 0;
      }
    },
    cpeObjectsForDITExport(cpeIds) {
      try {
        const result = [];
        const cpesIdsWithNODataAboutCpe = JSON.parse(JSON.stringify(cpeIds));
        for (const cpe of this.cpesList) {
          if (cpeIds.includes(cpe.id)) {
            result.push(cpe);
            const index = cpesIdsWithNODataAboutCpe.indexOf(cpe.id);
            if (index >= 0) {
              cpesIdsWithNODataAboutCpe.splice(index, 1);
            }
            // console.log(cpesIdsWithNODataAboutCpe)
          }
        }
        if (cpesIdsWithNODataAboutCpe.length > 0) {
          // если так получилось то какая-то из точек доступа, ID которой указан для выгрузки в
          // данный момент не найдена в API точек
          // создаем для такой точки объект с ID вместо имени и ID
          for (const cpeid of cpesIdsWithNODataAboutCpe) {
            result.push({ id: cpeid, name: cpeid });
          }
        }
        return result;
      } catch (e) {
        return [];
      }
    },
    tooltipForDITItemCpeColumnInTable(cpeIds) {
      const cpes = this.cpeObjectsForDITExport(cpeIds);
      let tooltip = '<ul class=\'list-unstyled text-left m-0\'>';
      for (const cpe of cpes) {
        tooltip += `<li><small>${cpe.name}</small></li>`;
      }
      tooltip += '</ul>';

      return tooltip;
    },
    linkForDITItemFTPColumnInTable(server, login, password, sftp, privateKey) {
      try {
        if (server && login && password && sftp !== undefined) {
          // console.log(`ftp://${login}:${password}@${server}`)
          if (sftp === true) {
            if (privateKey !== '') {
              return undefined;
            } else {
              return `sftp://${login}:${password}@${server}`;
            }
          }
          return `ftp://${login}:${password}@${server}`;
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    },
    updateDITExport() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.selectedCpesCount === 0) {
              this.showCpesLengthError();
            } else {
              // наполняем объект выгрузки ДИТ idшниками cpe, выбраннами в селекторе CPE, чтобы сохранить их
              const cpesIds = this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.map((cpe) => cpe.id);
              this.updatedOrNewDITExport.ids = cpesIds;
              // апдейтим
              DITStatService.updateDITExport(this);
            }
          } else if (this.selectedCpesCount === 0) {
            this.showCpesLengthError();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    createDITExport() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.selectedCpesCount === 0) {
              this.showCpesLengthError();
            } else {
              // наполняем объект выгрузки ДИТ idшниками cpe, выбраннами в селекторе CPE, чтобы сохранить их
              const cpesIds = this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.map((cpe) => cpe.id);
              this.updatedOrNewDITExport.ids = cpesIds;
              // апдейтим
              DITStatService.createDITExport(this);
            }
          } else if (this.selectedCpesCount === 0) {
            this.showCpesLengthError();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    deleteDITExport() {
      DITStatService.deleteDITExport(this);
    },
    toggleIsFiltersForCpeListBlockShowing() {
      if (this.filtersForCpeList.isFiltersForCpeListBlockShowing) {
        // this.clearFiltersForCpeList();
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = false;
      } else {
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = true;
        this.filtersForCpeList.isFiltersForCpeListEnabled = true;
        // this.filtersForCpeList.radarStatus = 'Enabled';
      }
    },
    clearFiltersForCpeList() {
      this.filtersForCpeList = {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations'
      };
    },
    addAllFilteredCpesToSelected() {
      this.hideCpesLengthError();
      const existingCpeIdsInExportCpes = [];
      for (const existingCpe of this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector) {
        existingCpeIdsInExportCpes.push(existingCpe.id);
      }
      for (const cpe of this.filterdCpesListForModal) {
        if (!existingCpeIdsInExportCpes.includes(cpe.id)) {
          this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.push(cpe);
        }
      }
    },
    clearSelectedCpesList() {
      // console.log(this.exportCpes);
      // this.exportCpes = []
      while (this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.length) {
        this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.pop();
      }
    }
  },
  // created() {
  //   this.getAllDitExpots();
  //   this.getCompactCpesList();
  //   this.getLocations();
  // },

  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    this.getAllDitExpots();
    this.getCompactCpesList();
    this.getLocations();
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    SFT_AUTH_TYPE() {
      return SFT_AUTH_TYPE;
    },
    isFiltersActive(){
      return this.filters.location  !== 'All locations'
    },
    DIT_EXPORT_FORMAT() {
      return DIT_EXPORT_FORMAT;
    },
    isloadingDITStatList() {
      return this.$store.state.loadingDITStatList;
    },
    isLoadingCpesList() {
      return this.$store.state.loadingCpes;
    },
    isLoadingLocationsList() {
      return this.$store.state.loadingLocations;
    },
    isUpdateDITExportReqExecuting() {
      return this.$store.state.updateDITExportReqExecuting;
    },
    isCreateDITExportReqExecuting() {
      return this.$store.state.createDITExportReqExecuting;
    },
    isDeleteDITExportReqExecuting() {
      return this.$store.state.deleteDITExportReqExecuting;
    },
    isShowOverTableSpinner() {
      return this.isloadingDITStatList || this.isLoadingCpesList || this.isLoadingLocationsList;
    },
    isShowOverCreateOrEditMOdalSpinner() {
      return (
        this.isUpdateDITExportReqExecuting || this.isCreateDITExportReqExecuting || this.isDeleteDITExportReqExecuting
      );
    },
    isShowOverDeleteModalSpinner() {
      return this.isDeleteDITExportReqExecuting;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    // filterdCpesListForModal() {
    //   this.$store.commit('filteringCpeListInDITExportModalWindowStart')
    //   try {
    //     let filtredCpes = JSON.parse(JSON.stringify(this.cpesList));
    //     if (this.filtersForCpeList.isFiltersForCpeListEnabled) {
    //       if (this.filtersForCpeList.location !== 'All locations') {
    //         filtredCpes = filtredCpes.filter((cpe) => {
    //           return cpe.base_location === this.filtersForCpeList.location;
    //         });
    //       }
    //     }
    //     this.$store.commit('filteringCpeListInDITExportModalWindowEnd')
    //     return filtredCpes;
    //   } catch (e) {
    //     console.log(e);
    //     this.$store.commit('filteringCpeListInDITExportModalWindowEnd')
    //     return this.cpesList;
    //   }
    // },
    filterdCpesListForModal() {
      this.$store.commit('filteringCpeListInDITExportModalWindowStart');
      try {
        let filtredCpes = JSON.parse(JSON.stringify(this.cpesList));
        if (this.filtersForCpeList.isFiltersForCpeListEnabled) {
          if (this.filtersForCpeList.location !== 'All locations') {
            filtredCpes = filtredCpes.filter((cpe) => cpe.base_location === this.filtersForCpeList.location);
          }
        }
        this.$store.commit('filteringCpeListInDITExportModalWindowEnd');

        if (this.showDescriptionInCpesFilter === true) {
          for (const cpeIndex in filtredCpes) {
            if (filtredCpes[cpeIndex].description !== '') {
              filtredCpes[
                cpeIndex
              ].name = `${filtredCpes[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${filtredCpes[cpeIndex].description}`;
            } else {
              filtredCpes[cpeIndex].name = `${filtredCpes[cpeIndex].name}`;
            }
          }
        }

        return filtredCpes;
      } catch (e) {
        console.log(e);
        this.$store.commit('filteringCpeListInDITExportModalWindowEnd');
        return this.cpesList;
      }
    },
    isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled() {
      // если в выпадающих списках что-то выбрано и фильтрация включена, выводим true, чтобы потом
      // показать плашку о том что филтрация включена в UI
      if (this.filtersForCpeList.isFiltersForCpeListEnabled && this.filtersForCpeList.location !== 'All locations') {
        return true;
      }
      return false;
    },
    isCpesListFiltering() {
      return this.$store.state.filteringCpeListInDITExportModalWindow;
    },
    selectedCpesCount() {
      try {
        if (Array.isArray(this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector)) {
          return this.cpesObjectsForUpdatedOrNewDITExporSelectedInCPESelector.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    }
  }
};
</script>
<style scoped>
  .dit-text-area-for-private-key{
    overflow: auto;
    min-height: 250px;
  }
.ip-row-in-dit-stat {
  width: 220px;
}
.ftp-type-badge-in-table {
  width: 30px;
  alignment-baseline: center;
  position: relative;
  top: -1px;
}
.cpes-show-description {
  font-size: 0.8em;
}
.showing-filters-for-cpe-list-block-button {
  margin-bottom: 10px;
}
.selected-cpes-counter-in-DIT-exports-modal {
  margin-top: -1.5em;
}
.max-width-column-dit-exports-table {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
</style>

<style>
                        .input-group-for-location-select-in-monitoringDITStat--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringDITStat--filters .select__list-container {
    width: 400px;
  }


  .ditExportsModalTransition-enter-active,
.ditExportsModalTransition-leave-active {
  transition: opacity 0.3s;
}

.ditExportsModalTransition-enter,
.ditExportsModalTransition-leave-to {
  opacity: 0;
}

.fadeforcpefiltersblockinDITExports-enter-active,
.fadeforcpefiltersblockinDITExports-leave-active {
  transition: opacity 0.7s;
}

.fadeforcpefiltersblockinDITExports-enter, .fadeforcpefiltersblockinDITExports-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.multiselect-max-height-in-DIT-export-modal .multiselect__tags {
  max-height: 90px !important;
  padding: 7px !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.multiselect-max-height-in-DIT-export-modal .multiselect__tags::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f1f1f1;
}

.multiselect-max-height-in-DIT-export-modal .multiselect__tags::-webkit-scrollbar-thumb {
  background: lightgray;
}
</style>
